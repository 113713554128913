import React from 'react';
import ProjectTemplate from "../components/ProjectTemplate";
import HeadSchysst from "../assets/headschysst.png";
import FinalSchysst from "../assets/finalSchysst.png";
import Persona1 from "../assets/Schysst-persona1.png";
import Persona2 from "../assets/Schysst-persona2.png";



const SchysstProject = () => {
  const projectData = {
    title: "A Family Recipe Game",
    mainDescription: (
      <>
        Schysst käk's vision is that food should be easy and fast to make, as well as appreciated by the whole family. My team of five were tasked to create a modern recipe application that is easy, funny, and doesn't have any unnecessary stuff. In this project, I took on the role of UI and UX Designer.
       </>
    ),
    sectionTitle: "Design solution",
    sectionDescription:
    (
        <>
            The app concept 'Laga tillsammans' is a game where users choose a recipe, create a team, and then get assigned different tasks required to finish the dish. It enables the whole family or a group of friends to be involved in the cooking.
            <br /><br /> 
            The app is tailored to Schysst Käk's graphic profile and features simple navigation to suit all ages. The sub-tasks of the recipe are distributed within the team as the game progresses. When a participant has completed their task, they click on it to get a new task. The game can be adjusted for children by activating the 'Kids Chef' mode. It’s a game that makes cooking a fun activity for the whole family where everyone needs to contribute.
        </>
    ),
    sideImage: HeadSchysst,  // Huvudbild
    columns: [
        { title: "User Research", text: "We started with a research phase where existing recipe services were studied and the target group's preferences were investigated through a survey. We defined the target user and created two personas." },
        { title: "Idea Generation", text: "In the next phase, we brainstormed three different concepts. All three concepts had different ways of approaching the target group as well as the aim of the original task. We produced Mid-Fi prototypes for each concept and tested them on the target group." },
        { title: "Final protoype", text: "We transformed the mid-fi prototype into a clickable high-fi prototype that follows Schysst Käk's graphic profile. Finally, several iterations of user testing were conducted to ensure a user-friendly solution." }
      ],
    gallery: [
      Persona1,  // Bild 1
      Persona2,  // Bild 2
      ,  // Galleri bild 1
      ,  // Galleri bild 2
      FinalSchysst  // Stor galleri bild
    ]
  };

  return <ProjectTemplate {...projectData} />;
};

export default SchysstProject;
