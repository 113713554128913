import "./NavbarStyles.css";
import React from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

const Navbar = () => {
    const [click, setClick] = React.useState(false);
    const handleClick = () => setClick(!click);

    const location = useLocation(); // Få den aktuella URL:en

    // Kontrollera om vi är på en projektsida
    const isProjectPage = location.pathname.includes('/project');

    return (
        <div className={`header ${isProjectPage ? 'hide-about' : ''}`}> 
            <RouterLink to="/">
                <h1>Lisa Lundqvist</h1>
            </RouterLink>

            <ul className={click ? "nav-menu active" : "nav-menu"}>
                {isProjectPage && (
                    <li>
                        <RouterLink to="/" onClick={handleClick}>
                            Home
                        </RouterLink>
                    </li>
                )}
                <li className="about-link">
                    <ScrollLink 
                        to="about" 
                        smooth={true} 
                        duration={500} 
                        offset={-70} 
                        onClick={handleClick} 
                    >
                        About
                    </ScrollLink>
                </li>
                <li>
                    <ScrollLink 
                        to="contact" 
                        smooth={true} 
                        duration={500} 
                        offset={-70} 
                        onClick={handleClick} 
                    >
                        Contact
                    </ScrollLink>
                </li>
            </ul>

            <div className="hamburger" onClick={handleClick}>
                {click ? (
                    <FaTimes size={20} style={{ color: "#fff" }} />
                ) : (
                    <FaBars size={20} style={{ color: "#000" }} />
                )}
            </div>
        </div>
    );
}

export default Navbar;
