import React from 'react';
import ProjectTemplate from "../components/ProjectTemplate";
import Head from "../assets/HelloLisa.png";


const PortfolioProject = () => {
  const projectData = {
    title: "Portfolio design and implementation",
    mainDescription: (
      <>
        Designing and developing my personal portfolio was a project aimed at showcasing my skills in UI/UX design and front end development. The objective was to create a space where I could present my work, highlight my experience, and express my creative vision.
        <br /><br />
        I previously had a portfolio that was difficult to update and quickly became outdated. The goal with this new design was to create something sustainable and easy to maintain as I add new projects. To achieve this, I built it using a component-based approach in React.js.       </>
    ),
    sectionTitle:[],
    sectionDescription: [],
    sideImage: [Head],  // Huvudbild
    columns: [
      ],
    gallery: [
      ,  // Bild 1
      ,  // Bild 2
      ,  // Galleri bild 1
      ,  // Galleri bild 2
        // Stor galleri bild
    ]
  };

  return <ProjectTemplate {...projectData} />;
};

export default PortfolioProject;
