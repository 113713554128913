import React from 'react';
import './ProjectTemplateStyles.css';

const ProjectTemplate = ({ title, mainDescription, sectionTitle, sectionDescription, sideImage, columns, gallery }) => {
  return (
    <div className="project-template">
      {/* Header Section */}
      <div className="project-header">
        <div className="project-text">
          <h1>{title}</h1>
          <p>{mainDescription}</p>
          {/* Rendera endast om sectionTitle och sectionDescription finns */}
          {sectionTitle && sectionDescription && (
            <>
              <h2>{sectionTitle}</h2>
              <p>{sectionDescription}</p>
            </>
          )}
        </div>
        {/* Rendera endast om sideImage finns */}
        {sideImage && (
          <div className="project-image">
            <img src={sideImage} alt="Project Main" />
          </div>
        )}
      </div>

      {/* Column Section */}
      {columns && columns.length > 0 && (
        <div className="project-section">
          <h2>The Journey</h2>
          <div className="section-columns">
            {columns.map((column, index) => (
              <div key={index} className="column">
                <h3>{column.title}</h3>
                <p>{column.text}</p>
              </div>
            ))}
          </div>
          {/* Rendera endast om de första två bilderna i gallery finns */}
          {gallery && gallery.length > 1 && (
            <div className="column-images">
              <img src={gallery[0]} alt="Gallery Image 1" />
              <img src={gallery[1]} alt="Gallery Image 2" />
            </div>
          )}
        </div>
      )}

      {/* Gallery Section */}
      {gallery && gallery.length > 4 && (
        <div className="project-gallery">
          <h2>Visuals</h2>
          {/* Rendera endast om de sista bilderna i gallery finns */}
          {/* Exempel med full bredd för den sista bilden */}
          <div className="gallery-row full-width">
            <img src={gallery[4]} alt="Gallery Image 5" />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectTemplate;
