import "./HeroImgStyles.css";
import heroImage from "../assets/1.jpg";
import GreetingAnimation from "./GreetingAnimation";

import React from 'react';


const HeroImg = () => {
  return (
    <div className="hero">
        <div className="grid-container">
          <div className="text-area">
            <GreetingAnimation />
          </div>
          <div className="image-area">
            <img src={heroImage} alt="Lisa" />
          </div>
        </div>
    </div>
  );
}

export default HeroImg;

