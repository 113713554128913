import FancyText from '@carefully-coded/react-text-gradient';
              
export default function AnimatedRadial() {
  return (
    <FancyText
      gradient={{ from: 'rgb(248 162 70)', to: 'rgb(242 80 129)' }}
      animate
      animateDuration={3000}
    >
      digital designer
    </FancyText>
  )
}