// projectData.js
import Img1 from "../assets/FrontOve.png";
import Img2 from "../assets/FrontDataTjej.jpg";
import Img3 from "../assets/FrontSvall.png";
import Img4 from "../assets/FrontSolwe.png";
import Img5 from "../assets/FrontSchysst2.png";
import Img6 from "../assets/FrontPortfolio.png";


export const projects = [
    {
      title: "Deal smart with OVE",
      description: "UX and UI Design",
      category: "Design",
      imgUrl: Img1,
      link: "/projectove"
    },
    {
      title: "DataTjej.se",
      description: "Redesign and implementation in WordPress",
      category: "Development",
      imgUrl: Img2,
      link: "/projectdatatjej"
    },

    {
        title: "UI for Sundsvall’s Mobile Care",
        description: "Concept Development, UX and UI Design",
        category: "Design",
        imgUrl: Img3,
        link: "/projectsvall"
      },

      {
        title: "The award winning app Solwe",
        description: "UX and UI Design",
        category: "Design",
        imgUrl: Img4,
        link: "/projectsolwe"
      },

      {
        title: "A Family Recipe Game",
        description: "UX and UI Design",
        category: "Design",
        imgUrl: Img5,
        link: "/projectschysst"
      },

      {
        title: "This Portfolio",
        description: "Design and implementation in React",
        category: "Development",
        imgUrl: Img6,
        link: "/projectportfolio"
      },

      
    // Lägg till fler projekt här
  ];