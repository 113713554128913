import React from 'react';
import ProjectTemplate from "../components/ProjectTemplate";
import HeadData from "../assets/DataTjejlogo.png";
import FinalTjej from "../assets/Final-DataTjej.png";
import WireframesOVE from "../assets/Wireframes-OVE.png";
import CComponents from "../assets/ColorcomponentsOve.png";



const DataTjejProject = () => {
  const projectData = {
    title: "DataTjej.se",
    mainDescription: (
      <>Datatjej is a Swedish organisation with the aim of promoting gender equality in the field of computer science and technology. Founded in 2003, the association is dedicated to increasing the representation and participation of women and non-binary individuals in the IT industry and technology sector.         <br /><br />
       During the board year 22/23, I took on the role of IT and web manager, which included responsibility for DataTjej.se and associated web hosting. DataTjej had an outdated and un-updated website in need of a redesign. </>
    ),
    sectionTitle: "The Result",
    sectionDescription: "Based on the graphic profile, the layout, buttons, navigation, and content were updated. The focus was on highlighting partners and DataTjej as an organization to create a professional and inviting impression. From a Figma prototype, the design was brought to life and implemented in WordPress.",
    sideImage: HeadData,  // Huvudbild
    gallery: [
      WireframesOVE,  // Bild 1
      CComponents,  // Bild 2
      ,  // Galleri bild 1
      ,  // Galleri bild 2
      FinalTjej   // Stor galleri bild
    ]
  };

  return <ProjectTemplate {...projectData} />;
};

export default DataTjejProject;
