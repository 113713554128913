import React from "react";
import "./index.css";
import Home from "./routes/Home";
import Ove from "./routes/ProjectOve";
import DataTjej from "./routes/ProjectDataTjej";
import Sundsvall from "./routes/ProjectSvall";
import Solwe from "./routes/ProjectSolwe";
import Schysst from "./routes/ProjectSchysst";
import Portfolio from "./routes/ProjectPortfolio";

import Navbar from "./components/Navbar";
import Footer from './components/Footer';

import { Routes, Route } from "react-router-dom";

function App() {
  return (
   <>
   <Navbar />
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projectove" element={<Ove />} />
        <Route path="/projectdatatjej" element={<DataTjej />} />
        <Route path="/projectsvall" element={<Sundsvall />} />
        <Route path="/projectsolwe" element={<Solwe />} />
        <Route path="/projectschysst" element={<Schysst />} />
        <Route path="/projectportfolio" element={<Portfolio />} />

    </Routes>
    <Footer />

   </>
  );
}

export default App;
