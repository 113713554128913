// ProjectsSection.js
import React, { useState } from 'react';
import { ProjectCard } from './ProjectCard';
import { projects } from './ProjectData';
import  "./ProjectStyles.css";


const ProjectsSection = () => {
  const [activeTab, setActiveTab] = useState("All");

  const filteredProjects = activeTab === "All" ? projects : projects.filter(project => project.category === activeTab);

  return (
    <section className="projects" id="projects">
      <div className="tabs-container">
        <h2 className="tabs-title">My creations in Code and Design</h2>
        <div className="tabs">
          <button className={activeTab === "All" ? "active" : ""} onClick={() => setActiveTab("All")}>All Projects</button>
          <button className={activeTab === "Design" ? "active" : ""} onClick={() => setActiveTab("Design")}>Design</button>
          <button className={activeTab === "Development" ? "active" : ""} onClick={() => setActiveTab("Development")}>Development</button>
        </div>
      </div>
      
      <div className="projects-grid">
        {filteredProjects.map((project, index) => (
          <ProjectCard key={index} {...project} />
        ))}
      </div>
    </section>
  );
};

export default ProjectsSection;
