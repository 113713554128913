import React, { useState, useEffect, useMemo } from 'react';
import Gradient from './Gradient';

const GreetingAnimation = () => {
  // Memoisera greetings-arrayen så att den inte skapas om vid varje render
  const greetings = useMemo(() => ['Hello', 'Hej', 'Bonjour', 'Hola', 'Ciao'], []);

  const [currentGreeting, setCurrentGreeting] = useState(greetings[0]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % greetings.length);
    }, 2000); // Byter hälsning varannan sekund

    return () => clearInterval(interval);
  }, [greetings.length]);

  useEffect(() => {
    setCurrentGreeting(greetings[index]);
  }, [index, greetings]);

  return (
    <div>
      <h1>{currentGreeting}, I’m Lisa.<br></br>A <Gradient></Gradient> <br></br>based in Sweden.</h1>
       <p>Loves creating and developing user-friendly solutions that exceed expectations</p>
 
    </div>
  );
};

export default GreetingAnimation;

