import React from 'react';
import img1 from '../assets/travel.jpg';
import img2 from '../assets/ski.jpg';
import img3 from '../assets/food.jpg';

import "./HobbiesStyles.css";


const HobbiesSection = () => {
  const hobbies = [
    {
      subtitle: "Discovering new places (whether it's in Stockholm or on a trip)",
      imgUrl: img1
    },
    {
      subtitle: "Being outdoors (preferably in a pair of running shoes or on a pair of skis)",
      imgUrl: img2
    },
    {
      subtitle: "Good food and fika (especially in the company of family and friends)",
      imgUrl: img3
    }
  ];

  return (
    <section className="hobbies-section">
      <h3>In my freetime, I enjoy...</h3>
      <div className="hobbies-grid">
        {hobbies.map((hobby, index) => (
          <div key={index} className="hobby-card">
            <p>{hobby.subtitle}</p>
            <img src={hobby.imgUrl} alt={hobby.subtitle} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default HobbiesSection;
