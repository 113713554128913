import "./AboutStyles.css";

import React from 'react';

const JourneySection = () => {
  return (
    <section className="journey-section" id="about">
      <div className="journey-content">
        <div className="journey-text">
          <h2>From Passion to Profession</h2>
          <p>
          My passion for tech and design has been with me since childhood and has only deepened over time. In June 2024, I completed a master's degree in interaction, technology, and design, where I developed my skills in both programming and design. My primary focus is always on the end user, ensuring I create the most effective and engaging user experiences. I am a naturally curious person, which fuels my drive to continually explore and adopt new technologies.          </p>
        </div>
        <div className="journey-tools">
          <h4>Tools I have used</h4>
          <p>
            Figma, Adobe Photoshop and Adobe Illustrator <br />
            HTML, CSS, JavaScript, React.js, Java, C#, C, SQL, ASP.NET and Python 
          </p>
        </div>
      </div>
    </section>
  );
};

export default JourneySection;
