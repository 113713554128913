import React from 'react';
import ProjectTemplate from "../components/ProjectTemplate";
import HeadOve from "../assets/HeadOve.png";
import FinalOve from "../assets/final-ove.png";
import WireframesOVE from "../assets/Wireframes-OVE.png";
import CComponents from "../assets/ColorcomponentsOve.png";



const ExampleProject = () => {
  const projectData = {
    title: "Deal smart with OVE",
    mainDescription: (
      <>
        OVE is a service that empowers users to shop smarter by highlighting their membership perks, including offers and discounts, and allowing them to set alerts for different categories or items. By using the user's location, OVE notifies them of nearby discounts based on their preferences.
        <br /><br />
        This project was conducted as part of the course 'Service Design and Business Models in an Engineering Context,' with the aim of developing a business idea and delivering a prototype. I collaborated with two other students and was responsible for the UX and UI design.      </>
    ),
    sectionTitle: "Design Idea",
    sectionDescription: "The design is tailored to suit a broad audience with clear contrasts, buttons, and easy navigation. With elements of black accents and rounded corners, the goal is for OVE to be perceived as a modern and user-friendly application.",
    sideImage: HeadOve,  // Huvudbild
    columns: [
      { title: "User Research", text: "The project began with researching the users and existing services. This was done through a survey, stakeholder interviews, and a market analysis." },
      { title: "Protoyping", text: "The initial prototypes consisted of simple low-fidelity prototypes to test the flow before moving on to mid-fidelity prototypes. After user testing, the final high-fidelity prototype was created. The prototypes was crafted in Figma." },
      { title: "User Testing", text: "To ensure a smooth flow, user experience, and design, user testing was conducted in various stages. This was done with different potential OVE users using task-based user testing and follow-up questions." }
    ],
    gallery: [
      WireframesOVE,  // Bild 1
      CComponents,  // Bild 2
      "path_to_image3.jpg",  // Galleri bild 1
      "path_to_image4.jpg",  // Galleri bild 2
      FinalOve   // Stor galleri bild
    ]
  };

  return <ProjectTemplate {...projectData} />;
};

export default ExampleProject;
