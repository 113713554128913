import React from "react";
import Navbar from "../components/Navbar";
import HeroImg from "../components/HeroImg";
import ProjectSection from "../components/ProjectSection";
import HobbiesSection from "../components/Hobbies";
import JourneySection from "../components/About";
import Footer from "../components/Footer";

const Home = () => {
  return (
    <div>
      <HeroImg />
      <ProjectSection />
      <JourneySection />
      <HobbiesSection />
    </div>
  )
}

export default Home;