import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

export const ProjectCard = ({ title, description, imgUrl, link }) => {
  return (
    <div className="project-card">
      <RouterLink to={link}>
        <img src={imgUrl} alt={title} />
        <h3>{title}</h3>
      </RouterLink>
      <p>{description}</p>
    </div>
  );
};
