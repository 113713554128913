import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

import "./FooterStyles.css";


const Footer = () => {
  return (
    <footer className="footer" id='contact'>
      <h3>Would you like to collaborate or get in touch?</h3>
      <p>Don't hesitate to contact me via <a href="mailto:lisaalundqvist@live.se">email</a> or <a href='https://www.linkedin.com/in/lisa-lundqvist/'>LinkedIn</a></p>
      <div className="footer-icons">
        <a href="mailto:lisaalundqvist@live.se" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faEnvelope} size="2x" />
        </a>
        <a href="https://www.linkedin.com/in/lisa-lundqvist/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} size="2x" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
