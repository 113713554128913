import React from 'react';
import ProjectTemplate from "../components/ProjectTemplate";
import HeadSvall from "../assets/HeadSvall.png";
import FinalSvall from "../assets/final-svall.jpg";
import PersonaSvall from "../assets/PersonaSvall.png";
import Components from "../assets/KomponenterSvall.png";



const SvallProject = () => {
  const projectData = {
    title: "UI for Sundsvall's Mobile Care",
    mainDescription: (
      <>
        A project that was carried out during the Summer Internship at Sundsvalls kommun. The aim of the project was to create a future vision and user interface for evening and night nurses who works in a mobile operation. They have many interfaces to keep track of in order to make everyday life work, such as papers, binders and various computer systems.
        <br /><br />
        In a team of two, I was responsible for the UX and UI design. The project applied the methodologies and principles of Design Thinking and Usability Heuristics. </>
    ),
    sectionTitle: "Solution",
    sectionDescription: "The design is based on Sundsvall Municipality's graphic profile and components. The purpose of the prototype is to show a way that gather all the information that nurses might need to make safe decisions and to communicate with their colleagues during their shifts.",
    sideImage: HeadSvall,  // Huvudbild
    columns: [
      { title: "User Research", text: "In the first phase, me and my collegue identified and defined the problem picture for evening and night nurses based on extended interviews and observations. We defined the end users by creating personas and use cases." },
      { title: "Idea Generation", text: "In the next phase, we began generating ideas based on the research by prioritizing requirements and potential content. We examined current systems and competing solutions." },
      { title: "Prototyping and Testing", text: "We continued with various iterations of prototyping. The prototypes were tested and evaluated continuously in collaboration with evening and night nurses to ensure a user-friendly interface adapted to them." }
    ],
    gallery: [
      PersonaSvall,  // Bild 1
      Components,  // Bild 2
      "path_to_image3.jpg",  // Galleri bild 1
      "path_to_image4.jpg",  // Galleri bild 2
      FinalSvall  // Stor galleri bild
    ]
  };

  return <ProjectTemplate {...projectData} />;
};

export default SvallProject;
