import React from 'react';
import ProjectTemplate from "../components/ProjectTemplate";
import HeadSolwe from "../assets/HeadSolwe.png";
import FinalSolwe from "../assets/final-solwe.png";



const SolweProject = () => {
  const projectData = {
    title: "The award winnng app Solwe",
    mainDescription: (
      <>
       The hackathon NASA Space Apps Challenge is an international competition with participants from all over the world. The task is to choose one of 28 challenges and come up with the best possible solution over a weekend. I participated in a team with five other girls in the Swedish competition arranged by HiQ. 
       <br /><br />
       My team chose the challenge 'You are my sunshine,' which involved creating an application designed to handle NASA's vast amount of data. My primary role was UI-designer and UX-researcher.
       </>
    ),
    sectionTitle: "Winning design",
    sectionDescription: "The design of Solwe is simple, playful, and presents complex data in an easy-to-understand way for people who own solar panels, enabling them to derive practical benefits from it. By using graphics in combination with text, the data becomes interpretable for everyday people. With this high-fidelity prototype, we won the competition in Sweden.",
    sideImage: HeadSolwe,  // Huvudbild
    columns: [
        ],
    gallery: [
      ,  // Bild 1
      ,  // Bild 2
      ,  // Galleri bild 1
      ,  // Galleri bild 2
      FinalSolwe  // Stor galleri bild
    ]
  };

  return <ProjectTemplate {...projectData} />;
};

export default SolweProject;
